import React from "react";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import { Link, graphql, StaticQuery } from "gatsby";
import { LIVE_URL } from "../../utils/constants";
import Newsletter from "../common/newsletter";

export default function StickyFooter({
  siteInfo,
  allStrapiQuickLinks,
  cardsLength,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  const getIcon = (name) => {
    if (name === "facebook") {
      return "/icons/newFBeIcon.svg";
    } else if (name === "instagram") {
      return "/icons/newInstaIcon.svg";
    } else if (name === "youtube") {
      return "/icons/youtubeIcon.png";
    } else if (name === "linkedin") {
      return "/icons/linkedInIcon.png";
    } else if (name === "twitter") {
      return "/icons/newTwitterIcon.svg";
    }
  };

  return (
    <Grid container className={classes.footerWrapper}>
      <Grid item container xs={12}>
        <Grid item xs={4}>
          <Grid item xs={12}>
            <Link
              to={`${LIVE_URL}`}
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <img
                src={siteInfo?.footer_logo?.publicURL}
                alt="omid"
                width="100"
                height="50"
                style={{ width: "100px" }}
                loading="lazy"
              />
            </Link>
          </Grid>
          <Grid item xs={12} container>
            <StaticQuery
              query={graphql`
                {
                  allStrapiSocialLinks {
                    edges {
                      node {
                        link
                        name
                        strapiId
                      }
                    }
                  }
                }
              `}
              render={(data) => {
                return (
                  <Grid
                    item
                    xs={11}
                    spacing={1}
                    container
                    style={{
                      // marginLeft: "1px",
                      paddingTop: "12px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {data?.allStrapiSocialLinks?.edges?.length > 0 &&
                      data?.allStrapiSocialLinks?.edges?.map((item, index) => {
                        return (
                          <Grid item key={index}>
                            <Link
                              to={item?.node?.link}
                              style={{ textDecoration: "none" }}
                              target="_blank"
                            >
                              <img
                                src={getIcon(item?.node?.name)}
                                alt={"icon"}
                                width="20"
                                height="20"
                                loading="lazy"
                              /*   style={{
                                  width: "20px",
                                }} */
                              />
                            </Link>
                          </Grid>
                        );
                      })}
                  </Grid>
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={8} container>
          <Grid item xs={12}>
            <Typography
              className={classes.subTitle}
              style={{ fontSize: isMobile && "24px", paddingBottom: "5px" }}
            >
              {siteInfo?.newletter_title}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Typography
              className={classes.description1}
              style={{ fontSize: isMobile && "18px", paddingTop: "5px" }}
            >
              {siteInfo?.newletter_subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} style={{ paddingTop: "20px" }}>
        <Newsletter />
      </Grid>

      <Grid xs={12} style={{ paddingTop: "0px" }} container>
        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          lg={3}
          xl={3}
          // style={{ maxHeight: "230px" }}
          style={{ paddingLeft: isMobile && "3%" }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.subTitle}
              style={{ fontSize: isMobile && "22px" }}
            >
              Quick Links
            </Typography>
          </Grid>

          {allStrapiQuickLinks?.edges?.map((item, index) => {
            if (item?.node?.name === "Subscriptions" && cardsLength <= 0) {
              return;
            }
            return (
              <Grid item xs={12} key={index} style={{ marginTop: "15px" }}>
                <Link
                  to={item?.node?.link}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    className={classes.description1}
                    style={{
                      fontSize: isMobile && "18px",
                    }}
                  >
                    {item?.node?.name}
                  </Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          lg={2}
          xl={3}
          style={{ paddingLeft: isMobile && "3%" }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.subTitle}
              style={{ fontSize: isMobile && "22px" }}
            >
              Help
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Link to="/terms" style={{ textDecoration: "none" }}>
              <Typography
                className={classes.description1}
                style={{ fontSize: "18px" }}
              >
                Terms
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Link to="/privacy" style={{ textDecoration: "none" }}>
              <Typography
                className={classes.description1}
                style={{ fontSize: isMobile && "18px" }}
              >
                Privacy
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Link
              to={`/cookies-policy`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Typography
                className={classes.description1}
                style={{ fontSize: isMobile && "18px" }}
              >
                Cookies Policy
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Typography
                className={classes.description1}
                style={{ fontSize: "18px" }}
              >
                Contact Us
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>

      {isXS && (
        <Grid
          container
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            className={classes.copyright}
            style={{ fontSize: isXS && "14px", marginTop: isXS && "10%" }}
          >
            Copyright @ {new Date().getFullYear()} {siteInfo?.title}. All Rights
            Reserved.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    // paddingTop: "10px",
    // paddingBottom: "50px",
    padding: "20px 15px 50px 15px",
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "42px",
    color: "#000000",
    letterSpacing: "0.05em",
  },
  subTitle: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "22px",
    color: "#FFFFFF",
    letterSpacing: "0.05em",
    // marginBottom: "3px",
    marginTop: "12px",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "151.69%",
    letterSpacing: "0.045em",
    // marginTop: '20px',
    color: "#FFFFFF",
  },
  description1: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.045em",
    color: "#FFFFFF",
  },

  copyright: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    letterSpacing: "0.045em",
    color: "#FFFFFF",
    marginTop: "2%",
  },
}));

// export const query = graphql`
//   {
//     allStrapiBlogs {
//       edges {
//         node {
//           content
//           description
//           strapiId
//           title
//           readingTime
//           published_at
//           image {
//             publicURL
//           }
//         }
//       }
//     }
//   }
// `
