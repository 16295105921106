import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({
  title,
  description,
  image,
  article,
  pageKeyword,
  keyword,
  name,
  url,
}) => {
  const { pathname } = useLocation();
  const { strapiSiteMetaData } = useStaticQuery(query);
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
    defaultKeyword,
  } = strapiSiteMetaData;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage?.publicURL}`,
    url: `${siteUrl}${url}`,
    keyword: pageKeyword ? pageKeyword : defaultKeyword,
    name: name || "Person",
  };

  return (
    <Helmet
      title={seo.title}
      titleTemplate={seo.title || titleTemplate}
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="keyword" content={keyword || seo.keyword} />
      {process.env.INDEX_PAGES && <meta name="robots" content="noindex" />}
      {process.env.INDEX_PAGES && <meta name="googlebot" content="noindex" />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
      <link
        as="stylesheet"
        rel="preload"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,800,900&display=swap"
      />
      <link
        as="stylesheet"
        rel="preload"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto:wght@100&family=Tenali+Ramakrishna&display=swap"
        rel="preload"
        as="stylesheet"
      />
      {/* <link
        href="//db.onlinewebfonts.com/c/3dd6e9888191722420f62dd54664bc94?family=Myriad+Pro"
        rel="stylesheet"
        type="text/css"
      /> */}
      <link
        rel="shortcut icon"
        href={strapiSiteMetaData?.defaultImage?.publicURL}
      />
      <script type="application/ld+json">
        {`
          {
                  "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": [{
                  "@type": "ListItem",
                "position": 1,
                "name": "Courses",
                "item": "https://omidlife.com/courses/"
          },{
                  "@type": "ListItem",
                "position": 2,
                "name": "Subscriptions",
                "item": "https://omidlife.com/subscription-plans"
          },{
                  "@type": "ListItem",
                "position": 3,
                "name": "OMID Tv",
                "item": "https://omidlife.com/view-all"
          }]
        }`}
      </script>
      {pathname === "/" ? (
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "OmidLife",
            "url": "https://www.omidlife.com/",
            "logo": "https://www.omidlife.com/static/34c9eeb91c59a4f6b2337b642c43b6ea/ec3c65883f6c0e5a4000f8775739ace2.png"
          }
          `}
        </script>
      ) : pathname.includes("/courses/") ? (
        <script type="application/ld+json">
          {`
{
  "@context": "https://schema.org/",
  "@type": "Course",
  "name": "${seo.title}" ,
  "description": "${seo.description}",
  "provider": {
    "@type": "Person",
    "name": "${seo.name}",
    "url": "${seo.url}"
  }
}
 `}
        </script>
      ) : (
        <script></script>
      )}
      ;
    </Helmet>
  );
};

export default SEO;
const query = graphql`
  {
    strapiSiteMetaData {
      strapiId
      defaultTitle: title
      titleTemplate
      defaultDescription: description
      siteUrl: url
      defaultImage: image {
        publicURL
      }
      twitterUsername
      defaultKeyword: keyword
    }
  }
`;
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};
