import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Grid, OutlinedInput } from "@material-ui/core";
import { FormHelperText } from "@material-ui/core";
import { SnackbarProvider, useSnackbar } from "notistack";
import { STRAPI_API_URL } from "../../utils/constants";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import newsLaterButton from "../../assets/icons/emailIcon.svg";

function MyApp() {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("xs"));

  async function subscribe() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email, unique: true }),
    };
    await fetch(
      // `${process.env.API_URL}/subscribers`,
      `${STRAPI_API_URL}subscribers`,

      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (!response?.error) {
          enqueueSnackbar(
            response.message ? response.message : "Subscribed Successfully!",
            { variant: "success" }
          );
        } else {
          enqueueSnackbar(
            response?.error ? response.error : "An Error Occurred!",
            { variant: "error" }
          );
        }
      });
  }

  const handleSubscribe = () => {
    if (email === "") {
      setEmailError("Enter Email Address");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Enter Valid Email Address");
    } else {
      setEmailError(null);

      subscribe();
    }
  };

  return (
    <Grid container>
      <Grid item xs={9} sm={9}>
        <OutlinedInput
          classes={{ root: classes.root, focused: classes.focused }}
          id="standard-multiline-static"
          fullWidth
          value={email}
          onChange={(e) => {
            setEmailError(null);
            setEmail(e.target.value);
          }}
          margin="none"
          placeholder="Email..."
          error={emailError ? true : false}
        />
        <FormHelperText style={{ color: "white" }}>{emailError}</FormHelperText>
      </Grid>
      <Grid
        item
        xs={3}
        sm={3}
        onClick={() => handleSubscribe()}
        style={{ textAlign: "right" }}
      >
        <img
          src={newsLaterButton}
          alt=""
          height="50px"
          style={{ cursor: "pointer" }}
          loading="lazy"
        />
      </Grid>
    </Grid>
  );
}

export default function ContactSidebar() {
  return (
    <SnackbarProvider maxSnack={3}>
      <MyApp />
    </SnackbarProvider>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#c9c9c9",
    height: "50px",
    borderRadius: "6px",
    border: "1px solid #C4C4C4",
    width: "100%",
  },
  focused: {
    border: "1px solid #C4C4C4",
  },
}));
