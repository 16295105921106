import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
// import Typography from "@material-ui/core/Typography"
import MenuItem from "@material-ui/core/MenuItem";
import { Menu, Grid, Button, useMediaQuery } from "@material-ui/core";

import {
  Home,
  InsertComment,
  ExitToApp,
  MenuBook,
  Tv,
  Info,
  Assignment,
  CardMembership,
} from "@material-ui/icons";
// import MoreIcon from "@material-ui/icons/MoreVert"
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";

import { Link } from "gatsby";

import { useLocation } from "@reach/router";
import { LIVE_URL } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "42px",
    // color: "#FFFFFF",
    // [theme.breakpoints.up('sm')]: {
    display: "block",
    // },
  },
  subTitle: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14px",
    // color: "#FFFFFF",
    // [theme.breakpoints.up('sm')]: {
    display: "block",
    // },
  },
  menuItem: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",

    fontSize: "20px",
    lineHeight: "28px",
    color: "#000",
    textTransform: "none",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
      color: "#000000",
    },
  },
  menuItemActive: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",

    fontSize: "20px",
    lineHeight: "28px",
    color: "#000000",
    textTransform: "none",
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  mobileMenuItem: {
    fontFamily: theme.palette.fontFamily.Reef,
    // backgroundColor: theme.palette.secondary.main,
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  navItem: {
    display: "flex",
    alignItems: "center",
  },
  sectionDesktop: {
    paddingTop: "5px",
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  letsStartBtn: {
    textTransform: "none",
    // width: "100%",
    // height: "67px",
    // background: theme.palette.secondary.light,
    background: "transparent",

    borderRadius: "59px",
    border: "2px solid transparent",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "20px",
    color: "#000",
    transition: "background 1s",
    minWidth: "155px",
    // background: theme.palette.secondary.light,
    border: "2px solid #FF6035",

    "&:hover": {
      background: "#FF6035",
      border: "2px solid transparent",
      color: "#FFF !important",
    },
  },
}));

export default function AppHeader({
  page,
  siteInfo,
  scrollToDiv,
  scrollToTeachers,
  scrollToCoursesDiv,
  scrollToHomeDiv,
  cardsLength,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMobile1 = useMediaQuery(theme.breakpoints.down("sm"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  // const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [joinButtonFontColor, setJoinButtonFontColor] = React.useState(
    "#FF6035"
  );
  const [joinButtonBgColor, setJoinButtonBgColor] = React.useState("#F3F3F3");
  const [headerBoxShadow, setHeaderBoxShadow] = React.useState(null);

  const [headerColor, setHeaderColor] = useState(
    page === "notFound" ||
      page === "terms" ||
      page === "privacy" ||
      page === "cookiesPolicy"
      ? theme.palette.secondary.light1
      : "transparent"
  );
  const [headerFontColor, setHeaderFontColor] = useState(
    page === "notFound" ||
      page === "terms" ||
      page === "privacy" ||
      page === "cookiesPolicy"
      ? "#000"
      : "#000"
  );

  // const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    // setAnchorEl(event.currentTarget)
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const menuId = 'primary-search-account-menu';
  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
  //     <MenuItem onClick={handleMenuClose}>My account</MenuItem>
  //   </Menu>
  // );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link
        style={{
          textDecoration: "none",
          color: "#FFFF",
        }}
        to={`/`}
        onClick={() => handleCoursesSectionScroll()}
      >
        <MenuItem
          onClick={handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Home />
          </IconButton>
          <p>Home</p>
        </MenuItem>
      </Link>

      <Link
        style={{ textDecoration: "none", color: "#FFFF" }}
        to={`/courses`}
        onClick={() => handleCoursesSectionScroll()}
      >
        <MenuItem
          onClick={handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <MenuBook />
          </IconButton>
          <p>Courses</p>
        </MenuItem>
      </Link>

      {cardsLength > 0 && (
        <Link
          style={{ textDecoration: "none", color: "#FFFF" }}
          to={`/subscription-plans`}
          // onClick={() => handleCoursesSectionScroll()}
        >
          <MenuItem
            onClick={handleProfileMenuOpen}
            className={classes.mobileMenuItem}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              color="inherit"
            >
              <CardMembership />
            </IconButton>
            <p>Subscriptions</p>
          </MenuItem>
        </Link>
      )}
      <Link
        style={{ textDecoration: "none", color: "#FFFF" }}
        to={`/view-all`}
        onClick={() => handleCoursesSectionScroll()}
      >
        <MenuItem
          onClick={handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Tv />
          </IconButton>
          <p>Omid TV</p>
        </MenuItem>
      </Link>

      <Link style={{ textDecoration: "none", color: "#FFFF" }} to="/blogs">
        <MenuItem className={classes.mobileMenuItem}>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Assignment />
          </IconButton>
          Blogs
        </MenuItem>
      </Link>
      <Link style={{ textDecoration: "none", color: "#FFFF" }} to="/about">
        <MenuItem
          onClick={handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Info />
          </IconButton>
          <p>About </p>
        </MenuItem>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "#FFFF" }}
        to={`${LIVE_URL}auth`}
        target="_self"
      >
        <MenuItem
          onClick={handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <ExitToApp />
          </IconButton>
          <p>Login </p>
        </MenuItem>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "#FFFF" }}
        to={`${LIVE_URL}auth?tab=signup`}
        target="_self"
      >
        <MenuItem
          onClick={handleProfileMenuOpen}
          className={classes.mobileMenuItem}
        >
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <p>Join For Free </p>
        </MenuItem>
      </Link>
    </Menu>
  );

  const listenScrollEvent = (e) => {
    if (typeof window !== undefined) {
      // if (location?.pathname === "/subscription-plans") {
      //   // if (window.scrollY > -10) {
      //   setHeaderColor(theme.palette.secondary.light1);
      //   setHeaderFontColor("#000000");
      //   // setMoreIconColor("#000000")
      //   setJoinButtonFontColor("#FF6035");
      //   setJoinButtonBgColor("transparent");
      //   setHeaderBoxShadow("0 3px 5px rgba(57, 63, 72, 0.3)");
      // } else {
      if (location?.pathname === "/") {
        if (window.scrollY > 5) {
          setHeaderColor(theme.palette.secondary.light1);
          setHeaderFontColor("#000000");
          // setMoreIconColor("#000000")
          setJoinButtonFontColor("#FF6035");
          setJoinButtonBgColor("transparent");
          setHeaderBoxShadow("0 3px 5px rgba(57, 63, 72, 0.3)");
        } else {
          setHeaderColor("transparent");
          setHeaderFontColor("#000000");
          // setMoreIconColor("inherit")
          setJoinButtonFontColor("#FF6035");
          setJoinButtonBgColor("#F3F3F3");
        }
      } else {
        if (
          (page !== "notFound" &&
            page !== "terms" &&
            page !== "cookiesPolicy") ||
          page === "privacy"
        )
          if (window.scrollY > 5) {
            setHeaderColor(theme.palette.secondary.light1);
            // setHeaderColor(theme.palette.secondary.main)
            setHeaderFontColor("#000000");
            // setMoreIconColor("#000000")
            setJoinButtonFontColor("#FF6035");
            setJoinButtonBgColor("transparent");
            setHeaderBoxShadow("0 3px 5px rgba(57, 63, 72, 0.3)");
          } else {
            setHeaderColor("transparent");
            setHeaderFontColor("#000");
            // setMoreIconColor("inherit")
            setJoinButtonFontColor("#FF6035");
            setJoinButtonBgColor("#F3F3F3");
          }
      }
      // }
    }
  };
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      window.addEventListener("scroll", (e) => {
        listenScrollEvent(e);
      });
    }
    // if (location?.pathname === "/subscription-plans") {
    //   // if (window.scrollY > -10) {
    //   setHeaderColor(theme.palette.secondary.light1);
    //   setHeaderFontColor("#000000");
    //   // setMoreIconColor("#000000")
    //   setJoinButtonFontColor("#FF6035");
    //   setJoinButtonBgColor("transparent");
    // }
    return () => {
      unmounted = true;
    };
  }, []);

  // const handleScroll = () => {
  //   if (location?.pathname === "/") {
  //     scrollToDiv()
  //     setTimeout(() => {
  //       handleMobileMenuClose()
  //     }, 500)
  //   }
  // }

  // const handleTeacherSectionScroll = () => {
  //   if (location?.pathname?.includes("/courses/")) {
  //     scrollToTeachers()

  //     setTimeout(() => {
  //       handleMobileMenuClose()
  //     }, 500)
  //   }
  // }
  const handleCoursesSectionScroll = () => {
    if (location?.pathname?.includes("/courses/")) {
      scrollToCoursesDiv();
      setTimeout(() => {
        handleMobileMenuClose();
      }, 500);
    }
  };
  const handleHomeSectionScroll = () => {
    if (location?.pathname?.includes("/")) {
      scrollToHomeDiv();
      setTimeout(() => {
        handleMobileMenuClose();
      }, 500);
    }
  };

  return (
    <div className={classes.grow}>
      <AppBar
        style={{
          background:
            isMobile1 && headerColor === "transparent" ? "#fff" : headerColor,
          boxShadow: headerColor === "transparent" ? "none" : headerBoxShadow,
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container>
            <Grid item container xs={11} sm={11} md={4} lg={4} xl={5}>
              <Grid item xs={12}>
                <Toolbar style={{ padding: "1%" }}>
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    onClick={handleHomeSectionScroll}
                    aria-label="Omid"
                  >
                    <img
                      src={siteInfo?.header_logo?.publicURL}
                      alt=""
                      width={isMobile ? "75" : "125"}
                      height={isMobile ? "40" : "65"}
                      // style={{ width: "50px", height: "60px" }}
                      /* style={{
                        width: isMobile ? "75px" : "125px",
                        // height: isMobile ? "40px" : "60px",
                      }} */
                    />
                  </Link>
                  {/* <Grid container>
                    <Grid item xs={12}>
                      <Link
                        to="/"
                        style={{ textDecoration: "none" }}
                        onClick={handleHomeSectionScroll}
                      >
                        <Typography
                          className={classes.title}
                          style={{
                            fontSize: isMobile && "22px",
                            // color: headerFontColor,
                          }}
                        >
                          {siteInfo?.title}
                        </Typography>
                      </Link>
                    </Grid>
                  </Grid> */}
                </Toolbar>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={1}
              sm={1}
              md={8}
              lg={8}
              xl={7}
              className={classes.sectionDesktop}
            >
              <Grid item xs={12}>
                <Toolbar>
                  <Grid container justify="space-evenly">
                    <Grid item className={classes.navItem}>
                      <Link
                        style={{ textDecoration: "none", color: "#FFFF" }}
                        to={`/courses/`}
                        onClick={() => handleCoursesSectionScroll()}
                      >
                        <Button
                          aria-label="Courses"
                          className={
                            location?.pathname?.includes("/courses")
                              ? classes.menuItemActive
                              : classes.menuItem
                          }
                          style={{
                            color: headerFontColor,
                            fontSize: isMD && "15px",
                          }}
                        >
                          Courses
                        </Button>
                      </Link>
                    </Grid>

                    {cardsLength > 0 && (
                      <Grid item className={classes.navItem}>
                        <Link
                          style={{ textDecoration: "none", color: "#FFFF" }}
                          to={`/subscription-plans`}
                          // onClick={() => handleCoursesSectionScroll()}
                        >
                          <Button
                            aria-label="Subscriptions"
                            className={
                              location?.pathname?.includes("/view-all")
                                ? classes.menuItemActive
                                : classes.menuItem
                            }
                            style={{
                              color: headerFontColor,
                              fontSize: isMD && "15px",
                            }}
                          >
                            Subscriptions
                          </Button>
                        </Link>
                      </Grid>
                    )}
                    <Grid item className={classes.navItem}>
                      <Link
                        style={{ textDecoration: "none", color: "#FFFF" }}
                        to={`/view-all`}
                        onClick={() => handleCoursesSectionScroll()}
                      >
                        <Button
                          aria-label="Omid TV"
                          className={
                            location?.pathname?.includes("/view-all")
                              ? classes.menuItemActive
                              : classes.menuItem
                          }
                          style={{
                            color: headerFontColor,
                            fontSize: isMD && "15px",
                          }}
                        >
                          Omid TV
                        </Button>
                      </Link>
                    </Grid>

                    <Grid item className={classes.navItem}>
                      <Link style={{ textDecoration: "none" }} to="/blogs">
                        <Button
                          aria-label="Blogs"
                          className={
                            location?.pathname?.includes("/blogs")
                              ? classes.menuItemActive
                              : classes.menuItem
                          }
                          style={{
                            color: headerFontColor,
                            fontSize: isMD && "15px",
                          }}
                        >
                          Blogs
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item className={classes.navItem}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFF",
                          display: "flex",
                          alignItems: "center",
                        }}
                        to="/about"
                      >
                        <Button
                          aria-label="About"
                          className={
                            location?.pathname?.includes("/about")
                              ? classes.menuItemActive
                              : classes.menuItem
                          }
                          style={{
                            color: headerFontColor,
                            fontSize: isMD && "15px",
                          }}
                        >
                          About
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item className={classes.navItem}>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFF",
                          display: "flex",
                          alignItems: "center",
                        }}
                        to={`${LIVE_URL}auth`}
                        target="_self"
                      >
                        <Button
                          aria-label="Login"
                          className={classes.menuItem}
                          style={{
                            color: headerFontColor,
                            fontSize: isMD && "15px",
                          }}
                        >
                          Login
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "#FFFF",
                          display: "flex",
                          alignItems: "center",
                        }}
                        to={`${LIVE_URL}auth?tab=signup`}
                        target="_self"
                      >
                        <Button
                          aria-label=" Join For Free"
                          variant="contained"
                          className={classes.letsStartBtn}
                          style={{
                            //color: headerFontColor,
                            color: joinButtonFontColor,
                            // border: `2px solid ${headerFontColor}`,
                            border: `2px solid ${joinButtonFontColor}`,
                            fontSize: isMD && "15px",
                            // background: joinButtonBgColor,
                          }}
                        >
                          Join For Free
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                </Toolbar>
              </Grid>
            </Grid>
          </Grid>

          <div className={classes.sectionMobile}>
            <Link
              style={{
                textDecoration: "none",
                color: "#FFFF",
                display: "flex",
                alignItems: "center",
              }}
              to={`${LIVE_URL}auth?tab=signup`}
              target="_self"
            >
              <Button
                aria-label="Join For Free"
                variant="contained"
                className={classes.letsStartBtn}
                style={{
                  //color: headerFontColor,
                  // color: "#FF6035",
                  // border: `2px solid #FF6035`,
                  // border: `2px solid ${headerFontColor}`,
                  // background: joinButtonBgColor,
                  border: `2px solid ${joinButtonFontColor}`,
                  color: joinButtonFontColor,
                  fontSize: isMD && "15px",
                }}
              >
                Join For Free
              </Button>
            </Link>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon style={{ color: headerFontColor }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {/* {renderMenu} */}
    </div>
  );
}
