import React from "react";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { Link, graphql, StaticQuery } from "gatsby";
import Newsletter from "../common/newsletter";

export default function StickyFooter({
  siteInfo,
  allStrapiQuickLinks,
  cardsLength,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));

  const getIcon = (name) => {
    if (name === "facebook") {
      return "/icons/newFBeIcon.svg";
    } else if (name === "instagram") {
      return "/icons/newInstaIcon.svg";
    } else if (name === "youtube") {
      return "/icons/youtubeIcon.png";
    } else if (name === "linkedin") {
      return "/icons/linkedInIcon.png";
    } else if (name === "twitter") {
      return "/icons/newTwitterIcon.svg";
    }
  };

  return (
    <Grid container className={classes.footerWrapper}>
      <Helmet>
        <meta
          name="keywords"
          content={process.env.FOOTER_KEYWORDS}
        />
      </Helmet>
      <Grid item xs={12} container style={{ padding: "0px 35px 0px 35px" }}>
        {isXS && (
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            container
            style={{ marginBottom: "2%" }}
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                className={classes.subTitle}
                style={{ fontSize: isMobile && "15px" }}
              >
                {siteInfo?.newletter_title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.description1}
                style={{ fontSize: isMobile && "12px" }}
              >
                {siteInfo?.newletter_subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12} container style={{ marginTop: "15px" }}>
              <Newsletter />
            </Grid>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          xl={2}
          container
          style={{ display: "flex", alignItems: "center" }}
        >
          <Grid
            item
            xs={11}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "12px",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }} target="_blank">
              <img
                src={siteInfo?.footer_logo?.publicURL}
                alt=""
                width="160"
                height="80"
                loading="lazy"
                /*   style={{ width: "150px" }} */
              />
            </Link>
          </Grid>

          <StaticQuery
            query={graphql`
              {
                allStrapiSocialLinks {
                  edges {
                    node {
                      link
                      name
                      strapiId
                    }
                  }
                }
              }
            `}
            render={(data) => {
              return (
                <Grid
                  item
                  xs={11}
                  container
                  spacing={2}
                  style={{
                    marginLeft: "3px",
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {data?.allStrapiSocialLinks?.edges?.length > 0 &&
                    data?.allStrapiSocialLinks?.edges?.map((item, index) => {
                      return (
                        <Grid item key={index}>
                          <Link
                            to={item?.node?.link}
                            style={{ textDecoration: "none" }}
                            target="_blank"
                          >
                            <img
                              src={getIcon(item?.node?.name)}
                              alt={"icon"}
                              width={isMD ? "20" : "35"}
                              height={isMD ? "20" : "35"}
                              loading="lazy"
                              /*   style={{
                                width: isMD ? "20px" : "35px",
                              }} */
                            />
                          </Link>
                        </Grid>
                      );
                    })}
                </Grid>
              );
            }}
          />
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          lg={3}
          xl={3}
          // style={{ maxHeight: "230px" }}
          style={{ padding: isMobile && "1%" }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.subTitle}
              style={{ fontSize: isMobile && "18px" }}
            >
              Quick Links
            </Typography>
          </Grid>

          {allStrapiQuickLinks?.edges?.map((item, index) => {
            if (item?.node?.name === "Subscriptions" && cardsLength <= 0) {
              return;
            }
            return (
              <Grid item xs={12} key={index} style={{ marginTop: "2%" }}>
                <Link
                  to={item?.node?.link}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    className={classes.description1}
                    style={{
                      fontSize: isMobile && "15px",
                    }}
                  >
                    {item?.node?.name}
                  </Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>

        <Grid
          item
          xs={6}
          sm={6}
          md={2}
          lg={2}
          xl={3}
          style={{ padding: isMobile && "1%" }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              className={classes.subTitle}
              style={{ fontSize: isMobile && "18px" }}
            >
              Help
            </Typography>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link to="/terms" style={{ textDecoration: "none" }}>
              <Typography
                className={classes.description1}
                style={{ fontSize: "15px" }}
              >
                Terms
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link to="/privacy" style={{ textDecoration: "none" }}>
              <Typography
                className={classes.description1}
                style={{ fontSize: isMobile && "15px" }}
              >
                Privacy
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link
              to={`/cookies-policy`}
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              <Typography
                className={classes.description1}
                style={{ fontSize: isMobile && "15px" }}
              >
                Cookies Policy
              </Typography>
            </Link>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Typography
                className={classes.description1}
                style={{ fontSize: "15px" }}
              >
                Contact Us
              </Typography>
            </Link>
          </Grid>
        </Grid>

        {!isXS && (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                className={classes.subTitle}
                // style={{ fontSize: isMobile && "15px" }}
              >
                {siteInfo?.newletter_title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.description1}
                style={{ fontSize: isMobile && "15px" }}
              >
                {siteInfo?.newletter_subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12} container style={{ marginTop: "15px" }}>
              <Newsletter />
            </Grid>

            {!isXS && (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  className={classes.copyright}
                  style={{ fontSize: isXS && "10px", marginTop: isXS && "10%" }}
                >
                  Copyright @ {new Date().getFullYear()} {siteInfo?.title}. All
                  Rights Reserved.
                </Typography>
              </Grid>
            )}
          </Grid>
        )}

        {isXS && (
          <Grid
            container
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              className={classes.copyright}
              style={{ fontSize: isXS && "10px", marginTop: isXS && "10%" }}
            >
              Copyright @ {new Date().getFullYear()} {siteInfo?.title}. All
              Rights Reserved.
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: "20px", marginBottom: "0px" , marginLeft: "20px",marginRight: "20px"}}>
            <Link to="/terms" style={{ textDecoration: "none" }}>
              <Typography
                className={classes.descriptionKeyword}
                style={{ fontSize: "15px" }}
              >
                {process.env.FOOTER_KEYWORDS}
              </Typography>
            </Link>
          </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    paddingTop: "10px",
    paddingBottom: "50px",
    backgroundColor: theme.palette.secondary.light,
  },
  title: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "42px",
    color: "#000000",
    letterSpacing: "0.05em",
  },
  subTitle: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "22px",
    color: "#FFFFFF",
    letterSpacing: "0.05em",
    // marginBottom: "3px",
    marginTop: "12px",
  },
  description: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "151.69%",
    letterSpacing: "0.045em",
    // marginTop: '20px',
    color: "#FFFFFF",
  },
  descriptionKeyword: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "1.5px",
    color: "#FFFFFF",
    textAlign:"center",
    textTransform: "capitalize",
  },
  description1: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    letterSpacing: "0.045em",
    color: "#FFFFFF",

  },

  copyright: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    letterSpacing: "0.045em",
    color: "#FFFFFF",
    marginTop: "2%",
  },
}));

// export const query = graphql`
//   {
//     allStrapiBlogs {
//       edges {
//         node {
//           content
//           description
//           strapiId
//           title
//           readingTime
//           published_at
//           image {
//             publicURL
//           }
//         }
//       }
//     }
//   }
// `
