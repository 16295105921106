export const RELATE_APP_BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://betaapp.omid.life";
export const RELATE_APP_API_URL =
  process.env.REACT_APP_API_URL || "https://betaapp.omid.life/api/v1";
// export const RELATE_APP_BASE_URL = "http://localhost:5000"
// export const RELATE_APP_API_URL = "http://localhost:5000/api/v1"
export const LIVE_URL =
  process.env.REACT_APP_LIVE_URL || "https://betaapp.omid.life/";

  export const LIVE_LANDING_URL =
  process.env.REACT_APP_LIVE_URL || "https://beta.omid.life/";

// export const STRAPI_API_URL = "http://localhost:1337/"
// export const PICTURE_STRAPI_API_URL = "http://localhost:1337"

export const PICTURE_STRAPI_API_URL =
  process.env.REACT_APP_PICTURE_STRAPI_API_URL ||
  "https://betalandingadmin.omid.life";
export const STRAPI_API_URL =
  process.env.REACT_APP_STRAPI_API_URL || "https://landingadmin.omid.life/";
