import React, { useEffect, useState } from "react";
import Header from "./header";
import Footer from "./footer";
import MobileFooter from "./mobileFooter";
import SEO from "../seo/seo";
import { useStaticQuery, graphql, Link } from "gatsby";
import { Typography, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import useFetch from "use-http";

export default function Container({
  children,
  page,
  seoData,
  scrollToDiv,
  scrollToTeachers,
  scrollToCoursesDiv,
  scrollToHomeDiv,
}) {
  const { strapiSiteInfo, allStrapiQuickLinks } = useStaticQuery(query);
  const theme = useTheme();
  const location = useLocation();
  const { get } = useFetch(`${process.env.REACT_APP_API_URL}`);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isXS = useMediaQuery(theme.breakpoints.down("xs"));
  const isMD = useMediaQuery(theme.breakpoints.down("md"));
  const [cardsLength, setCardsLength] = useState(0);

  const getData = async () => {
    try {
      let res = await get(`/static/getAllSubscriptionPlans`);
      setCardsLength(res?.data?.length);
    } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        declineButtonText="Reject"
        buttonText="Accept"
        setDeclineCookie={false}
        enableDeclineButton="true"
        cookieName="gatsby-gdpr-google-analytics"
        style={{ background: "#242C3A", color: "#A3A7AC" }}
        buttonStyle={{
          color: "#FFF",
          fontSize: "13px",
          backgroundColor: "#A9A9A9",
        }}
        declineButtonStyle={{
          color: "#FFF",
          fontSize: "13px",
          backgroundColor: "#A9A9A9",
        }}
        expires={150}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        onDecline={() => {
          initializeAndTrack("none");
        }}
      >
        This site uses cookies to store information on your computer. Some are
        essential to make our site work; others help us improve the user
        experience. By using the site, you consent to the placement of these
        cookies. Read our
        {<Link style={{ color: "#D8D9DC" }}>Cookie Policy</Link>} to learn more.
      </CookieConsent>

      <SEO
        title={seoData?.title}
        description={seoData?.description}
        image={seoData?.image}
        keyword={seoData?.keyword}
        name={seoData?.name}
        url={seoData?.url}
      />
      <header>
        <Header
          page={page}
          siteInfo={strapiSiteInfo}
          scrollToDiv={scrollToDiv}
          scrollToTeachers={scrollToTeachers}
          scrollToCoursesDiv={scrollToCoursesDiv}
          scrollToHomeDiv={scrollToHomeDiv}
          cardsLength={cardsLength}
        />
      </header>
      <main style={{ minHeight: "75vh", marginTop: isMobile && "56px" }}>
        {children}
      </main>
      <footer>
        {isXS ? (
          <MobileFooter
            siteInfo={strapiSiteInfo}
            allStrapiQuickLinks={allStrapiQuickLinks}
            cardsLength={cardsLength}
          />
        ) : (
          <Footer
            siteInfo={strapiSiteInfo}
            allStrapiQuickLinks={allStrapiQuickLinks}
            cardsLength={cardsLength}
          />
        )}
      </footer>
    </React.Fragment>
  );
}

const query = graphql`
  {
    strapiSiteInfo {
      header_logo {
        publicURL
      }
      footer_logo {
        publicURL
      }
      newletter_subtitle
      newletter_title
    }

    allStrapiQuickLinks {
      edges {
        node {
          link
          name
        }
      }
    }
  }
`;
